import { graphql, Link, useStaticQuery } from 'gatsby';
import SanityImage from 'gatsby-plugin-sanity-image';
import React from 'react';
import styled from 'styled-components';

const Navigation = styled.div`
  width: 100%;
  height: 15rem;
  position: absolute;
  top: 0;
  z-index: 7;
  .full {
    width: 100vw;
    height: 100%;
    position: relative;
    justify-content: end;
    align-items: center;
    img {
      position: absolute;
      left: 2.5rem;
      top: 0.5rem;
    }
  }
  .linkContainer {
    width: 70rem;
    height: 100%;
    position: absolute;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  nav {
    width: 100%;
    ul {
      width: 100%;
      justify-content: space-evenly;
      list-style-type: none;
      padding: 0;
      a {
        padding: 1.3rem;
        font-size: 1.75rem;
        color: var(--red);
        font-weight: bold;
        cursor: pointer;
        transition: all 0.25s ease;
        text-shadow: 0.1rem 0 0 var(--white), 0 0.1rem 0 var(--white), -0.1rem 0 0 var(--white), 0 -0.1rem 0 var(--white);
        &:hover {
          color: var(--lightred);
        }
      }
      a[aria-current='page'] {
        border-bottom: 1.5px solid var(--lightred);
      }
    }
  }
  .navBG {
    z-index: 1;
  }
  .inline {
    display: inline-flex;
  }
  .flex {
    display: flex;
    flex-flow: column nowrap;
    margin: 0;
    padding: 0;
  }
  .center {
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .navBG img {
    width: 150px;
  }
  #dropToggle input {
    width: 178px;
    height: 35px;
    position: absolute;
    cursor: pointer;
    opacity: 0;
    z-index: 2;
  }
  .inputFlex {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #dropmenu {
    background-color: var(--red);
    position: absolute;
    top: 150px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    box-shadow: 0 0 10px #85888c;
    transform-origin: 0% 0%;
    transform: translate(0%, -135%);
    transition: all 0.5s ease;
    overflow-y: auto;
    opacity: 0;
  }
  #dropmenu li {
    transition-delay: 2s;
  }
  #dropdown li:hover {
    border-bottom: 1.5px solid var(--white);
  }
  #dropmenu button {
    background-color: transparent;
    margin: 0;
    padding: 0;
    box-shadow: none;
    text-shadow: 1px 1px 5px #c2c2c2;
  }
  #dropToggle input:checked ~ .dropContainer {
    opacity: 1;
    transform: none;
    svg {
      transform: rotate(180deg);
    }
  }

  @media only screen and (max-width: 1080px) {
    .navBG {
      z-index: 0;
    }
  }
  @media only screen and (max-height: 375px) {
    height: 75px;
    .navBG {
      img {
        width: 90px;
      }
    }
  }
  /* Hide menu on small screens */
  @media only screen and (max-width: 975px) {
    display: none;
  }
`;

const MobileNavigation = styled.div`
  width: 100%;
  height: 100px;
  position: fixed;
  top: 0;
  z-index: 7;
  #menuToggle img {
    width: 130px;
    position: fixed;
    top: 10px;
    left: 25px;
  }
  #menuToggle {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 40px;
    right: 25px;
    z-index: 1;
    -webkit-user-select: none;
    user-select: none;
  }
  .menuInput {
    display: flex;
    width: 40px;
    height: 32px;
    position: absolute;
    cursor: pointer;
    opacity: 0;
    z-index: 2;
    bottom: 0;
    right: 0;
  }
  #menuToggle span {
    display: flex;
    position: relative;
    width: 50px;
    height: 5px;
    margin-bottom: 5px;
    border-radius: 3px;
    box-shadow: 3px 3px 5px var(--black);
    z-index: 1;
    transform-origin: 5px 0;
    background: var(--white);
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
  }
  #menuToggle span:first-child {
    transform-origin: 0% 0%;
  }
  #menuToggle span:nth-last-child(2) {
    transform-origin: 0% 100%;
  }
  #menuToggle input:checked ~ span {
    opacity: 1;
    transform: rotate(45deg) translate(-10px, -17px);
    box-shadow: none;
  }
  #menuToggle input:checked ~ span:nth-last-child(2) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }
  #menuToggle input:checked ~ span:nth-last-child(3) {
    transform: rotate(-45deg) translate(1px, 0);
  }
  #menu {
    width: 50%;
    height: 100%;
    margin: 0;
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    box-shadow: 0 0 10px #85888c;
    transform-origin: 0% 0%;
    transform: translate(100%, 0%);
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    overflow-y: auto;
    overflow-x: hidden;
    @media only screen and (max-width: 600px) {
      width: 100%;
    }
  }
  #menu li {
    transition-delay: 2s;
  }
  #menuToggle input:checked ~ .menuContainer {
    transform: none;
  }
  ul {
    list-style-type: none;
    max-width: 75%;
    display: flex;
    flex-flow: row wrap;
    margin: 10% auto 0;
    padding: 0;
    justify-content: start;
    button {
      width: 100%;
        a {
        font-size: 3rem;
        font-weight: bold;
      }
    }
  }
  nav {
    height: 100%;
    background-color: var(--red); 
  }
  nav ul {
    height: 100%;
    list-style-type: none;
    padding: 0;
    li {
      padding: 2rem;
      font-size: 2rem;
      font-weight: bold; 
      color: var(--white);
    }
    a {
      font-size: 2rem;
      color: var(--white);
      font-weight: bold;
      cursor: pointer;
      transition: all 0.5s ease;
      &:hover {
        border-bottom: var(--white);
      }
      &[aria-current='page'] {
        border-bottom: var(--white);
      }
    }
    @media only screen and (max-width: 325px) {
      a {
        font-size: 1.8rem;
      }
    }
  }
  .navBG {
    width: 100%;
    height: 100%;
    background-color: var(--red);
    box-shadow: 3px 5px 10px #000;
    z-index: 1;
  }
  .inline {
    display: inline-flex;
  }
  .flex {
    display: flex;
    flex-flow: column nowrap;
  }
  .center {
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .inputFlex {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top 1.5rem;
  }
  #dropmenu {
    width: 80%;
    margin: 0 auto;
    background-color: var(--red);
    position: absolute;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    box-shadow: 0 0 10px #85888c;
    transform-origin: 0% 0%;
    transform: translate(120%, -20%);
    transition: all 0.5s ease;
    overflow-y: auto;
    overflow-x: hidden;
    opacity: 0;
    z-index: 3;
  }
  #dropmenu li {
    transition-delay: 2s;
    margin-top: 1rem;
  }
  #dropToggle input:checked ~ .dropContainer {
    opacity: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
  .dropContainer .caretRight {
    position: absolute;
    right: 0;
    top: 50%;
  }
  /* Landscape Mode */
  @media only screen and (max-height: 500px) {
    ul {
      margin: 0 auto;
      justify-content: start;
    }
    .icons {
      width: 100px;
      height: 100%;
      display: flex;
      flex-flow: column nowrap;
    }
    #dropmenu {
      transform: translate(135%, 0%);
    }
    .inputFlex {
      margin-top: 1.5rem;
      align-items: end;
    }
  }
  @media only screen and (max-height: 375px) {
    height: 75px;
    #menuToggle {
      top: 25px;
      img {
        width: 80px;
      }
    }
  }
  /* Hide menu on small screens */
  @media only screen and (min-width: 976px) {
    display: none;
  }
`;


export default function Nav() {
  const { navitems } = useStaticQuery(graphql`
    query {
      navitems: allSanityNavigation {
        nodes {
          id
          title
          mainalt
          mobilealt
          pagelinks {
            pagelink
            pagename
          }
          mobilelogo {
            asset {
              id
            }
            ...ImageWithPreview
          }
          navlogo {
            asset {
              id
            }
            ...ImageWithPreview
          }
        }
      }
    }
  `)

const nodes = navitems.nodes;
const [checked, setChecked] = React.useState(false || '');
  return (
    <>
      <Navigation>
        {nodes.map((node) => (
          <div className="full navBG flex" key={node.id}>
            <Link to='/'>
              <SanityImage 
                {...node.navlogo} 
                alt={node.mainalt}
                style={{
                  objectFit: 'contain',
                  auto: 'format',
                }}
              />
            </Link>
            <div className="linkContainer">
              <nav>
                <ul className='inline'>
                  {node.pagelinks.map((item) => (
                    <li key={item._key}>
                      <Link to={item.pagelink}>{item.pagename}</Link>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
          </div>
        ))}
      </Navigation>
      <MobileNavigation>
        {nodes.map((node) => (
          <div id="menuToggle" key={node.id}>
            <Link to='/'>
              <SanityImage 
                {...node.mobilelogo} 
                alt={node.mobilealt}
                style={{
                  objectFit: 'contain',
                  auto: 'format',
                }}
              />
            </Link>
            <input 
              type="checkbox" 
              className='menuInput'
              checked={checked}
              onClick={() => {setChecked(old => !old)}} 
            />
            <span />
            <span />
            <span />
            <div id="menu" className="menuContainer">
              <div className="navBG">
                <div className="center">
                  <nav>
                    <ul className='flex center'>
                      {node.pagelinks.map((item) => (
                        <li key={item._key}>
                          <Link to={item.pagelink}>
                            {item.pagename}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        ))}
      </MobileNavigation>
    </>
  );
}
