import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import SanityImage from 'gatsby-plugin-sanity-image'
import styled from 'styled-components'
import { defaultComponents, PortableText } from '@portabletext/react'
import fb from '../images/rmws-facebook.png'
import li from '../images/rmws-linkedin.png'

const FooterStyles = styled.footer`
  width: 100vw;
  height: 40rem;
  margin: 0;
  position: absolute;
  bottom: 0;
  background-color: rgba(100, 100, 100, 1);
  color: var(--white);
  font-size: 1rem;
  z-index: 10;
  .inline {
    display: inline-flex;
  }
  @media only screen and (max-width: 950px) {
    height: 77rem;
  }
`

const NodeParser = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`

const UpperFooter = styled.div`
  width: 100%;
  text-align: justify;
  justify-content: center;
  @media only screen and (max-width: 950px) {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-around;
    align-items: center;
  }
`

const Mission = styled.div`
  width: 24.5rem;
  height: 100%;
  padding-top: 1rem;
  margin: 0 6rem;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  .inline {
    align-items: center;
  }
`

const Contact = styled.div`
  width: 24.5rem;
  height: 100%;
  padding-top: 1rem;
  margin: 0 6rem;
  .inline {
    align-items: center;
  }
  p {
    margin: 4rem 0;
    text-align: left;
  }
`

const H4 = styled.h4`
  padding-top: 3rem;
  padding-bottom: 5px;
  border-bottom: 2px solid var(--gold);
  font-size: 2rem;
`

const Container = styled.div`
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-flow: column nowrap;
  text-align: left;
  a {
    color: var(--white);
    transition: all 0.25s ease-in-out;
    &:hover {
      color: var(--accent);
    }
  }
  @media only screen and (max-width: 950px) {
    padding: 0;
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(auto, 1fr));
  margin: 1rem 0;
`

const Icon = styled.div`
  grid-column: 1 / span 1;
  justify-self: center;
`

const Content = styled.span`
  font-size: 1.5rem;
  align-self: center;
  grid-column: 2 / span 2;
`

const Social = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
`

const SocialLink = styled.a`
  div {
    width: 5rem;
    height: 5rem;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    margin: 1rem;
    &:hover {
      box-shadow: 0.1rem 0.1rem 1rem var(--accent);
    }
  }
`

const Facebook = styled.div`
  background-image: url(${fb});
`

const Linkedin = styled.div`
  background-image: url(${li});
`

const LowerFooter = styled.div`
  width: 100%;
  padding: 1rem 0;
  background-color: #004075;
  position: absolute;
  bottom: 0;
  .inline {
    flex-wrap: wrap;
    justify-content: center;
  }
  .column {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    @media only screen and (max-width: 950px) {
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-around;
      align-items: center;
    }
  }
`

const List = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;
  font-size: 1rem;
  .span {
    padding: 0 1rem;
  }
`

const Item = styled.li`
  padding-bottom: 0.5rem;
  a {
    color: var(--white);
    cursor: pointer;
    &:hover {
      border-bottom: 1px solid var(--white);
    }
    &[aria-current='page'] {
      border-bottom: 1px solid var(--white);
    }
  }
  @media only screen and (max-width: 950px) {
    padding: 0 1rem;
    font-size: 0.9rem;
  }
`

const ExternalLink = styled.a`
  color: var(--white);
  cursor: pointer;
  &:hover {
    border-bottom: 1px solid var(--white);
  }
  &[aria-current='page'] {
    border-bottom: 1px solid var(--white);
  }
`

export default function Footer() {
  const { footer } = useStaticQuery(graphql`
    query {
      footer: allSanityFooter {
        nodes {
          id
          _rawMission
          businessname
          contacttitle
          dev
          devlink
          linktitle
          logoalt
          newslettertitle
          title
          contactinfo {
            _key
            contacttitle
            iconalt
            contactcontent
            contacticon {
              asset {
                id
              }
              ...ImageWithPreview
            }
          }
          footerlogo {
            asset {
              id
            }
            ...ImageWithPreview
          }
          links {
            _key
            pagelink
            pagename
          }
          privterms {
            _key
            pagename
            pagelink
          }
        }
      }
    }
  `)

  const { nodes } = footer
  return (
    <FooterStyles>
      {nodes.map(node => (
        <NodeParser key={node.id}>
          <UpperFooter className="inline">
            <Mission>
              <SanityImage
                {...node.footerlogo}
                alt={node.logoalt}
                style={{
                  width: '160px',
                  height: '160px',
                  objectFit: 'contain',
                  auto: 'format',
                }}
              />
              <PortableText
                value={node._rawMission}
                components={defaultComponents}
              />
            </Mission>
            <Contact>
              <H4>{node.contacttitle}</H4>
              <Container>
                <Grid>
                  <Icon>
                    <SanityImage
                      {...node.contactinfo[0].contacticon}
                      alt={node.contactinfo[0].iconalt}
                      style={{
                        width: '30px',
                        height: '30px',
                        objectFit: 'contain',
                        auto: 'format',
                      }}
                    />
                  </Icon>
                  <Content className="contactcontent content">
                    <a href={`tel:${node.contactinfo[0].contactcontent}`}>
                      {`(${node.contactinfo[0].contactcontent
                        .split('', 3)
                        .join('')}) ${node.contactinfo[0].contactcontent
                        .split('')
                        .slice(3, 6)
                        .join('')}-${node.contactinfo[0].contactcontent
                        .split('')
                        .slice(6, 10)
                        .join('')}`}
                    </a>
                  </Content>
                </Grid>
                <Grid>
                  <Icon>
                    <SanityImage
                      {...node.contactinfo[1].contacticon}
                      alt={node.contactinfo[1].iconalt}
                      style={{
                        width: '30px',
                        height: '30px',
                        objectFit: 'contain',
                        auto: 'format',
                      }}
                    />
                  </Icon>
                  <Content className="contactcontent content">
                    <a href={`mailto:${node.contactinfo[1].contactcontent}`}>
                      {node.contactinfo[1].contactcontent}
                    </a>
                  </Content>
                </Grid>
                <Grid>
                  <Icon>
                    <SanityImage
                      {...node.contactinfo[2].contacticon}
                      alt={node.contactinfo[2].iconalt}
                      style={{
                        width: '30px',
                        height: '30px',
                        objectFit: 'contain',
                        auto: 'format',
                      }}
                    />
                  </Icon>
                  <Content className="contactcontent content">
                    {node.contactinfo[2].contactcontent}
                  </Content>
                </Grid>
              </Container>
              <Social className="inline">
                <SocialLink
                  href="https://www.facebook.com/pages/Rocky-Mountain-Wireline-Service/104363879630844"
                  rel="noopener"
                  target="_blank"
                >
                  <Facebook />
                </SocialLink>
                <SocialLink
                  href="https://www.linkedin.com/company/rocky-mountain-wireline-svc/"
                  rel="noopener"
                  target="_blank"
                >
                  <Linkedin />
                </SocialLink>
              </Social>
            </Contact>
          </UpperFooter>
          <LowerFooter>
            <List className="column">
              <Item>
                &copy; All Rights Reserved {node.businessname}{' '}
                {new Date().getFullYear()}
              </Item>
              <Item>
                <List className="inline">
                  <Item>
                    <Link to={node.privterms[0].pagelink}>
                      {node.privterms[0].pagename}
                    </Link>
                  </Item>
                  <Item className="span"> | </Item>
                  <Item>
                    <Link to={node.privterms[1].pagelink}>
                      {node.privterms[1].pagename}
                    </Link>
                  </Item>
                  <Item className="span"> | </Item>
                  <Item>
                    <ExternalLink
                      href="https://www.cigna.com/legal/compliance/machine-readable-files"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      MRFs for Transparency in Coverage Rule
                    </ExternalLink>
                  </Item>
                </List>
              </Item>
              <Item>
                <ExternalLink
                  href={node.devlink}
                  rel=" noopener noreferrer"
                  target="_blank"
                >
                  Built by {node.dev}
                </ExternalLink>
              </Item>
            </List>
          </LowerFooter>
        </NodeParser>
      ))}
    </FooterStyles>
  )
}
