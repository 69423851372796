import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
/* CSS Reset by Mirkov Sasa https://github.com/mirkovsasa/CSS-Reset/blob/main/Reset.css */

/* Resetting defaults */
* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
    box-sizing: border-box;
}

/* Setting border box model for easier sizing of elements */
*, *::before, *::after {
  box-sizing: border-box;
}

/* Setting font size to make 10px = 1rem for easier scaling and manipulation of all elements in rem's */
html {
    font-size: 62.5%;
}

/* Setting default distance between the lines */
body {
    line-height: 1;
}

/* inheriting text for consistency */
input, button, textarea, select {
  font: inherit;
}

/* Removing default styles for lists */    
ol, ul {
    list-style: none;
}

/* Removing defaults styles for quotes */    
blockquote, q {
    quotes: none;
}
    
blockquote:before, blockquote:after,
    q:before, q:after {
    content: '';
    content: none;
}
    
/* Removing default outline styles for elements */
:focus {
    outline: 0;
}
    
/* Removing table defaults */
table {
    border-collapse: collapse;
    border-spacing: 0;
}

/* Changing default img properties for easier workflow */
img, picture {
    max-width: 100%;
    display: block;
}
/* End Reset */

:root {
    --black: #000;
    --white: #fff;
    --gray: #333333;
    --gold: #D6A616;
    --accent: #B8B8D1;
    --red: #710A0C;
    --call: #C0F5FA;
    --lightred: #E26D5C;
    --lightgreen: #90EE90;
    --green: #08890B;
  }
  html, body {
    min-height: 100%;
    max-width: 100vw;
    margin: 0;
  }
  html {
    background-color: var(--white);
    font-size: 10px;
    overflow-x: hidden;
  }
  body {
    position: relative;
  }
  .buttonesque {
    width: 200px;
    padding: 10px 10px;
    color: var(--red);
    font-size: 2rem;
    font-weight: 500;
    background-color: transparent;
    border: 1px solid var(--red);
    border-radius: 5px;
    cursor: pointer;
    &:hover {
      background-color: var(--red);
      color: var(--white);
    }
  }
  button {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    box-shadow: 3px 3px 10px var(--black);
    background-color: var(--lightgreen);
    color: var(--gray);
    font-weight: bold;
    font-size: 2.5rem;
    transition: all 0.25s ease-in-out;
    cursor: pointer;
    &:hover {
      background-color: var(--green);
      color: var(--white);
    }
    @media only screen and (max-height: 400px) {
      padding: 1rem;
      font-size: 2rem;
    }
  }
  .gatsby-image-wrapper img[src*=base64\\,] {
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
  } 

  /* Scrollbar Styles */
  body::-webkit-scrollbar {
    width: 12px;
  }
  html {
    scrollbar-width: thin;
    scrollbar-color: var(--lightred) #fff;
  }
  body::-webkit-scrollbar-track {
    background: transparent;
  }
  body::-webkit-scrollbar-thumb {
    background-color: var(--lightred) ;
    border-radius: 6px;
  }
  img {
    max-width: 100%;
  }
`;

export default GlobalStyles;
